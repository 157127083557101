
import { defineComponent, ref } from 'vue';
import FtTabs from './index';
export default defineComponent({
  name: 'TabsDemo',
  components: {
    FtTabs,
  },
  setup(props, { emit }) {
    const currentTabName = ref('');
    // text.value = 222;
    return {
      currentTabName,
    };
  },
});
