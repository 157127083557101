
import { defineComponent } from 'vue';
import InputDemo from './components/input/demo.vue';
import FormDemo from './components/form/demo.vue';
import TabsDemo from './components/tabs/demo.vue';

export default defineComponent({
  name: 'App',
  components: {
    InputDemo,
    FormDemo,
    TabsDemo,
  },
});
