
import { defineComponent, ref } from 'vue';
import FtInput from './index';
export default defineComponent({
  name: 'InputDemo',
  components: {
    FtInput,
  },
  setup(props, { emit }) {
    const text = ref('aaa');
    // text.value = 222;
    return {
      text
    };
  },
});
